import axios from 'axios';

// Use environment variable with fallback
const REPORT_BASE_URL = process.env.REACT_APP_REPORT_API_URL || 'https://reports.api.coredatastore.com/api';

/**
 * Handles report service errors with a consistent pattern
 *
 * @param {Error} error - The error object
 * @param {string} methodName - Name of the method where error occurred
 * @param {Object} fallback - Fallback response object
 * @returns {Object} Standardized error response
 */
const handleServiceError = (error, methodName, fallback) => {
  console.error(`Error in reportService.${methodName}:`, error);
  return fallback || { data: null, error };
};

/**
 * Fetches report types from the Report Service API
 *
 * @returns {Promise<{data: Array|null, error: Error|null}>} Report types or error
 */
export const getReportTypes = async () => {
  try {
    // Use axios directly for external API calls to avoid URL nesting issues with action.js
    const response = await axios.get(`${REPORT_BASE_URL}/Reference/reportTypes`);

    return { data: response.data, error: null };
  } catch (err) {
    return handleServiceError(err, 'getReportTypes', {
      data: null,
      error: err
    });
  }
};

/**
 * Fetches Excel report for specified report type
 *
 * @param {string} reportType - The type of report to fetch
 * @returns {Promise<{data: Blob|null, error: Error|null}>} Excel report blob or error
 */
export const getExcelReport = async (reportType) => {
  try {
    const headers = { 'Accept': 'application/vnd.ms-excel' };

    // Use axios with responseType: 'blob' for Excel file download
    const response = await axios.get(`${REPORT_BASE_URL}/ExcelReports`, {
      params: { ReportType: reportType },
      headers: headers,
      responseType: 'blob'
    });

    return { data: response.data, error: null };
  } catch (err) {
    return handleServiceError(err, 'getExcelReport', {
      data: null,
      error: err
    });
  }
};
