import React, { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from './components/common/Header';  // Updated import
import ErrorBoundary from './components/common/ErrorBoundary';
import LoadingState from './components/shared/LoadingState';

// Lazy loading the components
const ListingPage = lazy(() => import('./components/listing/ListingPage'));
const Details = lazy(() => import('./components/details/DetailsPage'));
const NotFound = lazy(() => import('./components/common/NotFound'));

const App = () => {
    return (
        <ErrorBoundary>
            <Header />
            <Suspense fallback={<LoadingState />}>
                <Routes>
                    <Route path='/' element={<ListingPage />} />
                    <Route path='/details' element={<Details />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Suspense>
        </ErrorBoundary>
    );
}

export default App;
