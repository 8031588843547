import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    currentFilters: {
        selectedType: '',
        selectedBorough: '',
        selectedNeighborhood: '',
        selectedParentStyle: [],
        filterText: '',
        limit: 10,
        currentPage: 1,
    },
    selectedItem: null,
    listingPageScrollY: 0
};

export const filtersSlice = createSlice({
    name: 'appStatus',
    initialState,
    reducers: {
        set_currentFilters: (state, action) => {
            state.currentFilters = action.payload;
        },
        set_selectedItem: (state, action) => {
            state.selectedItem = action.payload
        },
        set_listingPageScrollY: (state, action) => {
            state.listingPageScrollY = action.payload
        }
    }
});

export const { set_currentFilters, set_selectedItem, set_listingPageScrollY } = filtersSlice.actions;

export const get_currentFilters = (state) => state.appStatus.currentFilters;
export const get_selectedItem = (state) => state.appStatus.selectedItem;
export const get_listingPageScrollY = (state) => state.appStatus.listingPageScrollY;

export default filtersSlice.reducer;
