import { configureStore } from '@reduxjs/toolkit';
import appStatusSlice from './appStatusSlice';


export const store = configureStore({
  reducer: {
    // global slices -----
    appStatus: appStatusSlice
  },
});
