import React from 'react';
import loading from '../../assets/images/loading.png';

const LoadingState = ({ title }) => (
    <div className='w-full h-[300px] flex flex-col text-[20px] leading-[30px] items-center justify-center'>
        <img src={loading} alt='loading' className='w-[60px] h-[60px] animate-spin mb-[10px]' />
        Loading
    </div>
);

export default LoadingState;
