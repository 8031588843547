import React from 'react';
import PropTypes from 'prop-types';

/**
 * CloseIcon component - Renders an SVG close/X icon for modals and dismissible elements
 *
 * @param {Object} props - Component props
 * @param {string} [props.width="14"] - Width of the icon
 * @param {string} [props.height="14"] - Height of the icon
 * @param {string} [props.className=""] - Additional CSS classes to apply
 * @returns {JSX.Element} The rendered SVG icon
 */
const CloseIcon = ({ width = "14", height = "14", className = "" }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        className={className}
    >
        <path
            d="M1 1L13 13M1 13L13 1"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
        />
    </svg>
);

// Add displayName for better debugging experience
CloseIcon.displayName = 'CloseIcon';

CloseIcon.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    className: PropTypes.string
};

export default CloseIcon;
