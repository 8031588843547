import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { getReportTypes, getExcelReport } from '../../services/reportService';
import { formatedTimestamp } from '../../utils/format-date';
import LoadingState from '../shared/LoadingState';
import ErrorState from '../shared/ErrorState';
import CloseIcon from '../svg/CloseIcon';

// Set app element for screen readers (should be done once in your app)
if (process.env.NODE_ENV !== 'test') {
  Modal.setAppElement('#root');
}

/**
 * Modal component for generating and downloading LPC reports
 *
 * @param {Object} props - Component props
 * @param {boolean} props.isOpen - Whether the modal is open
 * @param {Function} props.onRequestClose - Function to close the modal
 * @returns {JSX.Element} ReportsModal component
 */
const ReportsModal = ({ isOpen, onRequestClose }) => {
    const [reportTypes, setReportTypes] = useState([]);
    const [selectedReportType, setSelectedReportType] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (isOpen) {
            setLoading(true);
            setError(null);

            getReportTypes()
                .then(response => {
                    setLoading(false);

                    if (response.error) {
                        setError(response.error);
                        return;
                    }

                    // Check if response.data exists and is an array
                    if (response.data && Array.isArray(response.data)) {
                        setReportTypes(response.data);
                        if (response.data.length > 0) {
                            setSelectedReportType(response.data[0].key);
                        }
                    } else {
                        setError(new Error('Invalid report types data received'));
                    }
                })
                .catch(err => {
                    console.error('Error fetching report types:', err);
                    setLoading(false);
                    setError(err);
                });
        }
    }, [isOpen]);

    const handleReportTypeChange = (event) => {
        setSelectedReportType(event.target.value);
    };

    const handleDownloadReport = async () => {
        try {
            setLoading(true);
            const response = await getExcelReport(selectedReportType);
            setLoading(false);

            if (response.error) {
                setError(response.error);
                return;
            }

            if (response.data) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `report_${selectedReportType}_${formatedTimestamp()}.xlsx`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                window.URL.revokeObjectURL(url);
            }
        } catch (err) {
            console.error('Error downloading report:', err);
            setLoading(false);
            setError(err);
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="LPC Reports Modal"
            style={{
                content: {
                    top: '60px',
                    right: '0px',
                    left: 'auto',
                    bottom: 'auto',
                    padding: '0',
                    border: 'none',
                    background: 'transparent',
                    overflow: 'visible'
                },
                overlay: {
                    backgroundColor: 'transparent'
                }
            }}
            aria-labelledby="reports-modal-title"
        >
            <div
                role="dialog"
                aria-modal="true"
                className="w-[400px] bg-white border border-1 border-border-primary shadow-[0px_12px_24px_rgba(0,0,0,0.08)] rounded-[8px] p-[12px] z-[50] flex flex-col relative"
                data-testid="reports-modal"
            >
                {/* Header with consistent styling */}
                <div className="flex items-center justify-between p-2 bg-gray-100 border-b border-gray-300 mb-3 rounded-t-[4px]">
                    <h2
                        id="reports-modal-title"
                        className="font-bold text-gray-900 whitespace-nowrap text-[18px]"
                    >
                        LPC Reports
                    </h2>
                    <button
                        onClick={onRequestClose}
                        className="text-gray-700 hover:text-gray-900 transition-colors"
                        aria-label="Close reports modal"
                        data-testid="close-reports-modal"
                    >
                        <CloseIcon />
                    </button>
                </div>

                {loading && <LoadingState />}

                {error && <ErrorState message={error.message || 'An error occurred'} />}

                {!loading && !error && (
                    <>
                        <div className="mb-4">
                            <label htmlFor="report-type-select" className="block text-typo-secondary text-[14px] mb-2">
                                Select Report Type
                            </label>
                            <select
                                id="report-type-select"
                                className="w-full border border-border-primary rounded-[4px] p-2 text-typo-primary focus:outline-none focus:border-blue-primary"
                                value={selectedReportType}
                                onChange={handleReportTypeChange}
                                data-testid="report-type-select"
                            >
                                {reportTypes.map((type, index) => (
                                    <option key={index} value={type.key}>{type.value}</option>
                                ))}
                            </select>
                        </div>

                        <div className="flex flex-row-reverse">
                            <button
                                onClick={handleDownloadReport}
                                className="w-fit bg-blue-primary text-white py-[8px] px-[16px] mt-[8px] rounded-[4px] cursor-pointer hover:bg-blue-600 transition-colors disabled:bg-gray-300 disabled:cursor-not-allowed"
                                disabled={loading || !selectedReportType}
                                data-testid="download-report-button"
                                aria-label="Download selected report"
                            >
                                Download Report
                            </button>
                        </div>
                    </>
                )}
            </div>
        </Modal>
    );
};

ReportsModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onRequestClose: PropTypes.func.isRequired
};

export default ReportsModal;
