import React, { useState, useCallback } from 'react';
import ReportsModal from '../reportsModal/ReportsModal';

/**
 * Header component - Main navigation header with reports modal functionality
 *
 * @returns {JSX.Element} The rendered header component
 */
const Header = () => {
    // State for modal visibility
    const [modalIsOpen, setModalIsOpen] = useState(false);

    // Event handlers
    const openModal = useCallback(() => setModalIsOpen(true), []);
    const closeModal = useCallback(() => setModalIsOpen(false), []);

    return (
        <header className='w-full bg-blue-primary flex items-center justify-between p-5 text-white'>
            <div className='cursor-pointer text-[32px] leading-[32px] font-semibold'>CoreDataStore</div>
            <nav className='flex text-[20px] leading-[24px]'>
                <div
                    className='cursor-pointer mx-[20px] hover:opacity-60'
                    onClick={openModal}
                    role="button"
                    tabIndex={0}
                    onKeyDown={(e) => e.key === 'Enter' && openModal()}
                    aria-label="Open LPC Reports"
                >
                    LPC Reports
                </div>
            </nav>

            {/* Reports Modal */}
            <ReportsModal isOpen={modalIsOpen} onRequestClose={closeModal} />
        </header>
    );
};

export default Header;
